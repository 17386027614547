import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login')
    },
    {
        path: '/questionnaire/categories/:type?/:action?',
        name: 'QuestionnaireCategories',
        component: () => import('../pages/QuestionnaireCategories'),
        props: true
    },
    {
        path: '/questionnaire/:action?/:id?',
        name: 'Questionnaire',
        component: () => import('../pages/Questionnaire2'),
        props: true
    },
    {
        path: '/questionnaire2/:action?/:id?',
        name: 'Questionnaire2',
        component: () => import('../pages/Questionnaire2'),
        props: true
    },
    {
        path: '/questionnaire/:id/questions/:action?/:subject_id?',
        name: 'QuestionnaireQuestions',
        component: () => import('../pages/QuestionnaireQuestions'),
        props: true
    },
    {
        path: '/questionnaire/results',
        name: 'QuestionnaireResults',
        component: () => import('../pages/QuestionnaireResults'),
        props: true
    },
    {
        path: '/partner-list',
        name: 'PartnerList',
        component: () => import('../pages/PartnerList'),
        props: true
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../pages/Orders'),
        props: true
    },
    {
        path: '/questionnaire/:id/questions-benefits',
        name: 'QuestionsBenefits',
        component: () => import('../pages/QuestionsBenefits'),
        props: true
    },
    {
        path: '/questionnaire/:id/news',
        name: 'QuestionsNewsEdit',
        component: () => import('../pages/QuestionsNewsEdit'),
        props: true
    },
    {
        path: '/partners',
        name: 'Partners',
        component: () => import('../pages/Partners'),
        props: true
    },
    {
        path: '/partner-contacts',
        name: 'PartnerContacts',
        component: () => import('../pages/PartnerContacts'),
        props: true
    },
    {
        path: '/user-offers',
        name: 'UserOffers',
        component: () => import('../pages/UserOffers'),
        props: true
    },
    {
        path: '/incoming-requests',
        name: 'IncomingRequests',
        component: () => import('../pages/IncomingRequests'),
        props: true
    },
    {
        path: '/cyborg',
        name: 'CyborgPage',
        component: () => import('../pages/Cyborg'),
        props: true
    },
    {
        path: '/cyborg-card-page',
        name: 'CyborgCardPage',
        component: () => import('../pages/Cyborg/CardPage'),
        props: true
    },
    {
        path: '/cyborg/:id',
        name: 'InstrumentPage',
        component: () => import('../pages/Cyborg/InstrumentPage'),
        props: true
    },
    {
        path: '/scenarios/variable-editor',
        name: 'VariableEditor',
        component: () => import('@page/VariableEditor'),
        props: true
    },
    {
        path: '/scenarios/list',
        name: 'ScenariosList',
        component: () => import('@page/ScenariosList'),
        props: true
    },
    {
        path: '/scenario/:id',
        name: 'Scenario',
        component: () => import('@page/Scenario'),
        props: true
    },
    {
        path: '/calculate',
        name: 'Calculate',
        component: () => import('@page/Calculate'),
        props: true
    },
    {
        path: '/404',
        name: 'NotFound',
        component: () => import('../pages/NotFound'),
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
